export default function AfterSignUpPage() {
    return (
      <>
        <h1>Thanks for applying!</h1>
        <h3>We'll be in touch soon.</h3>
        <br /><br /><br />
        <p>If you are confused about why you are seeing this page, please log out to submit another application.</p>
        
      </>
    );
  }